import { EfacityActionLogo } from '@efacity/frontend-shared';
import { useAuth } from '@efacity/react-next-sc';
import { AuthStatus } from '@efacity/common';
import { PATHS } from '@efacity/routing';
import { Route, Routes, useMatch } from 'react-router-dom';
import { AuthTabs } from '../../components/AuthForm/FormTabs/AuthTabs';
import { LandingWrapper } from '../../components/LandingWrapper/LandingWrapper';
import { useGoogleAuth } from '../../hooks/useGoogleAuth';
import { CenteredFormContainer } from '@efacity/frontend-next-shared/forms/server';
import { EmailValidation } from '../EmailValidation/EmailValidation';
import { ResetPassword } from './ResetPasswordPage';
import { SignInPage } from './SignInPage';
import { SignUpPage } from './SignUpPage';

export const AuthPage = () => {
  const {
    authState: { isAuthenticated }
  } = useAuth();
  const [signInUrl] = useGoogleAuth(isAuthenticated);
  const isSignInPage = useMatch({ path: '/auth/sign-in' });
  const isSignUpPage = useMatch({ path: '/auth/sign-up' });

  /*
    After user agreed to info access on Google auth page
    It will redirect back to Auth page
    Then useGoogleAuth will redirect user to appropriate page
  */
  return isAuthenticated === AuthStatus.Checking ? (
    <EfacityActionLogo message={'Checking auth status...'} />
  ) : (
    <LandingWrapper>
      <CenteredFormContainer>
        {(isSignInPage || isSignUpPage) && <AuthTabs />}
        <Routes>
          <Route path={PATHS.signIn} element={<SignInPage signInUrl={signInUrl} />} />
          <Route path={PATHS.signUp} element={<SignUpPage signInUrl={signInUrl} />} />
          <Route path={PATHS.resetPassword} element={<ResetPassword />} />
          <Route path={PATHS.emailValidation} element={<EmailValidation />} />
        </Routes>
      </CenteredFormContainer>
    </LandingWrapper>
  );
};
