import { Access, hasAnyOfRoles, Roles } from '@efacity/common';
import { AdministratorFullInfo } from '@efacity/frontend-shared';
import { AuthenticatedUserInfo } from '@efacity/common';

const mainAdmin = (access: Access[], id: string | undefined) => {
  return !!access.find((accessItem) => accessItem.organization === id && accessItem.isMainAdmin);
};

export const canDeleteCenterAdmin = (
  adminInfo: AdministratorFullInfo,
  authenticatedUser: AuthenticatedUserInfo,
  id?: string
) => {
  if (hasAnyOfRoles([Roles.SiteAdmin], authenticatedUser.roles)) {
    return true;
  }
  // if authenticated user is school admin && adminInfo is not main admin can delete
  if (hasAnyOfRoles([Roles.SchoolAdmin], authenticatedUser.roles)) {
    return true;
  }
  if (
    hasAnyOfRoles([Roles.CenterAdmin], authenticatedUser.roles) &&
    mainAdmin(authenticatedUser.access, id) &&
    !mainAdmin(adminInfo.access, id)
  ) {
    return true;
  }
  return false;
};

export const canDeleteSchoolAdmin = (
  adminInfo: AdministratorFullInfo,
  authenticatedUser: AuthenticatedUserInfo,
  id?: string
) => {
  if (hasAnyOfRoles(authenticatedUser.roles, [Roles.SiteAdmin])) {
    return true;
  }
  return mainAdmin(authenticatedUser.access, id) && !mainAdmin(adminInfo.access, id);
};
