import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { Box, Divider, MenuItem, Paper, Select, Tooltip } from '@mui/material';
import { blockButtons, headingOptions, markButtons } from './modificatorButtons';
import EditorButton from './EditorButton/EditorButton';
import { insertLink, isBlockActive, isLinkActive, isMarkActive, toggleBlock, toggleMark, unwrapLink } from './helpers';
import Element from './Element';
import Leaf from './Leaf';
import { HOTKEYS } from './constants';
import { createEditor, Descendant } from 'slate';
import AddLinkButton from './LinkButton/AddLinkButton';
import RemoveLinkButton from './LinkButton/RemoveLinkButton';
import withLinks from './withLinks';
import { Resizable } from 're-resizable';

const paperStyle = (minEditorHeight: number | 'auto', readOnly: boolean) => ({
  minHeight: 50,
  height: minEditorHeight,
  boxShadow: readOnly && 'none'
});

const buttonsWrapBreakpoint = 515;

const editableSx = (readOnly, editorHeight) => {
  return (theme) => ({
    px: (theme) => theme.spacing(1),
    overflow: 'auto',
    maxHeight: readOnly ? 'none' : editorHeight - 56,
    [theme.breakpoints.down(buttonsWrapBreakpoint)]: { maxHeight: readOnly ? 'none' : editorHeight - 112 }
  });
};

export interface RichTextEditorProps {
  editor?: ReactEditor;
  value: Descendant[];
  slatKey?: number;
  onChange?: (value: Descendant[]) => void;
  editorHeight?: number;
  placeholder?: string;
  readOnly?: boolean;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  value,
  slatKey = 0,
  onChange,
  editorHeight = 600,
  placeholder = '',
  readOnly = false
}) => {
  const editor = useMemo(() => withLinks(withReact(createEditor() as ReactEditor)), []);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const [headingSize, setHeadingSize] = useState(headingOptions[0].format);

  useEffect(() => {
    editor.children = value;
  }, [editor, value]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!event.ctrlKey) {
      return;
    }
    const mark = HOTKEYS[event.key];
    toggleMark(editor, mark);
  };

  const onHeadingSizeChange = (e) => {
    const selectedSize = e.target.value;
    setHeadingSize(selectedSize);
    toggleBlock(editor, selectedSize);
  };

  const [editorSize, setEditorSize] = useState({
    height: editorHeight,
    startResizeWidth: 0,
    startResizeHeight: 0
  });

  const minEditorHeight = readOnly ? 'auto' : editorSize.height;

  return (
    <Resizable
      defaultSize={{
        width: '100%',
        height: minEditorHeight
      }}
      minHeight={minEditorHeight}
      enable={{
        top: false,
        right: false,
        bottom: !readOnly,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
      onResizeStart={(e, dir, refToElement) => {
        setEditorSize((sizes) => ({
          ...sizes,
          startResizeHeight: sizes.height
        }));
      }}
      onResize={(e, direction, ref, d) => {
        setEditorSize((sizes) => ({
          ...sizes,
          height: sizes.startResizeHeight + d.height
        }));
      }}
    >
      <Paper square sx={paperStyle(minEditorHeight, readOnly)}>
        <Slate editor={editor} initialValue={value} key={slatKey} onChange={onChange}>
          {!readOnly && (
            <>
              <Box display="flex" style={{ flexWrap: 'wrap' }} py={1}>
                {markButtons.map((buttonProps) => (
                  <EditorButton
                    key={buttonProps.format}
                    {...buttonProps}
                    isSelected={isMarkActive(editor, buttonProps.format)}
                    onClick={(format) => toggleMark(editor, format)}
                  />
                ))}

                <Select
                  value={headingSize}
                  onChange={onHeadingSizeChange}
                  variant="outlined"
                  size="small"
                  style={{ width: 200 }}
                >
                  {headingOptions.map((option) => (
                    <MenuItem key={option.format} value={option.format}>
                      <Tooltip title={option.hint}>{option.icon}</Tooltip>
                    </MenuItem>
                  ))}
                </Select>

                {blockButtons.map((buttonProps) => (
                  <EditorButton
                    key={buttonProps.format}
                    {...buttonProps}
                    isSelected={isBlockActive(editor, buttonProps.format)}
                    onClick={(format) => toggleBlock(editor, format)}
                  />
                ))}

                <AddLinkButton
                  isSelected={isLinkActive(editor)}
                  onInsertLink={(url: string) => insertLink(editor, url)}
                />
                <RemoveLinkButton
                  onRemoveLink={() => {
                    if (isLinkActive(editor)) {
                      unwrapLink(editor);
                    }
                  }}
                />
              </Box>
              <Divider style={{ color: 'gray' }} />
            </>
          )}
          <Box sx={editableSx(readOnly, editorSize.height)}>
            <Editable
              readOnly={readOnly}
              data-testid="rich-text-editor"
              placeholder={placeholder}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onKeyDown={onKeyDown}
            />
          </Box>
        </Slate>
      </Paper>
    </Resizable>
  );
};

export default RichTextEditor;
