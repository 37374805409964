import { Roles, getLoggedInUserHomePagePathIfAny, hasAnyOfRoles, AuthStatus } from '@efacity/common';
import { REDIRECT_ITEM_NAME, useAuth, useLocalStorage } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface AuthCheckerProps {
  redirectPath?: string;
  acceptableRoles?: Roles[];
  children: React.ReactNode;
}
export const DEFAULT_AUTH_REDIRECT_PATH = `${PATHS.auth}/${PATHS.signIn}`;

// Wrapper for protected pages
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
const AuthChecker: FC<AuthCheckerProps> = ({
  children,
  redirectPath = DEFAULT_AUTH_REDIRECT_PATH,
  acceptableRoles = []
}) => {
  const location = useLocation();
  const { setItem, removeItem } = useLocalStorage();
  const {
    authState: { isAuthenticated, user, isLogout }
  } = useAuth();
  const roles = user?.roles ? user.roles : [];
  const hasAccess =
    isAuthenticated === AuthStatus.Authenticated &&
    (acceptableRoles.length === 0 || hasAnyOfRoles(acceptableRoles, roles));
  if (hasAccess) {
    removeItem(REDIRECT_ITEM_NAME);
    return <div>{children}</div>;
  }

  if (isAuthenticated === AuthStatus.Authenticated) {
    const userHomePage = getLoggedInUserHomePagePathIfAny(user?.access);
    if (userHomePage) {
      return <Navigate to={userHomePage} replace />;
    }
  }

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  if (isAuthenticated === AuthStatus.Unauthenticated && !isLogout) {
    setItem(REDIRECT_ITEM_NAME, location.pathname);
  } else {
    removeItem('from');
  }
  return <Navigate to={redirectPath} replace />;
};

export default AuthChecker;
