import { PaymentPlatform } from '@efacity/common';
import { createColumnHelper, TableOptions } from '@tanstack/react-table';
import Box from '@mui/material/Box';
import { CircularProgressButton, ConnectedAccount } from '@efacity/frontend-shared';
import Link from '@mui/material/Link';
import { PATHS, toPath } from '@efacity/routing';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<PaymentPlatform>();

export const columnDefs = (
  handleDeleteStripeAccountClicked: (accountId: string, accountName: string) => void,
  onDisconnectCLick: (accountId: string, stripeUserId: string) => void
): TableOptions<PaymentPlatform>['columns'] => [
  columnHelper.accessor('name', {
    header: 'Name',
    enableColumnFilter: false,
    enableSorting: true,
    cell: ({ getValue, row }) => {
      return (
        <Link href={toPath(PATHS.editPaymentPlatform, { id: row.original._id })} underline={'none'}>
          {getValue()}
        </Link>
      );
    }
  }),
  columnHelper.accessor('stripePlatformAccount.currencyCode', {
    header: 'Currency',
    enableColumnFilter: false,
    enableSorting: false
  }),
  columnHelper.accessor('stripePlatformAccount.isEfacity', {
    header: 'Efacity Default',
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ getValue }) => {
      return getValue() ? 'Yes' : '';
    }
  }),
  columnHelper.accessor('stripePlatformAccount', {
    header: 'Connected accounts',
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row, getValue }) => {
      return getValue().connectedAccounts?.length > 0
        ? getValue().connectedAccounts.map((connectedAccount: ConnectedAccount, index: number) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                style={{ marginTop: 2, marginBottom: 2 }}
                key={`connectedAccountsLine${index}`}
              >
                <div>{connectedAccount.name}</div>
                <div>
                  <CircularProgressButton
                    progress={false}
                    color="primary"
                    onClick={() => onDisconnectCLick(row.original._id, connectedAccount.stripeUserId)}
                    style={{ width: 100 }}
                    data-testid={`disconnectButtonForPaymentPlatform${row.original._id}`}
                  >
                    Disconnect
                  </CircularProgressButton>
                </div>
              </Box>
            );
          })
        : '-';
    }
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      return (
        <IconButton
          color="primary"
          aria-label="delete payment account"
          data-testid="delete-payment-account"
          component="span"
          style={{ padding: 0 }}
          onClick={() => handleDeleteStripeAccountClicked(row.original._id, row.original.name)}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  })
];
