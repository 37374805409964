import React, { useEffect, useState } from 'react';
import { FormMode } from '@efacity/common';
import { Box } from '@mui/material';
import { useAddressesLoader } from '../hooks/useAddressesLoader';
import { AddressModal } from './AddressModal';
import { NewButton } from './NewButton';

const addAddressButtonContainerStyle = {
  marginBottom: 15,
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end'
};

export const defaultAddressModalState = {
  isOpen: false,
  addressId: null,
  mode: FormMode.Add
};

export interface SchoolAddressesContainerProps {
  orgId: string;
}

export const OrganizationAddressesContainer: React.FC<SchoolAddressesContainerProps> = ({ orgId }) => {
  const [addressModalState, setAddressModalState] = useState(defaultAddressModalState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ addressesState }, { getAddresses, updateAddress, addAddress }] = useAddressesLoader(
    `/org/${orgId}/addresses`
  );

  useEffect(() => {
    getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAddAddress() {
    setAddressModalState({ isOpen: true, addressId: null, mode: FormMode.Add });
  }

  function handleCloseModal() {
    setAddressModalState(defaultAddressModalState);
  }

  return (
    <div style={{ marginTop: '15px' }}>
      <Box style={addAddressButtonContainerStyle}>
        <NewButton data-testid="addBtn" onClick={() => handleAddAddress()}>
          Add address
        </NewButton>
      </Box>
      {addressModalState.isOpen && (
        <AddressModal
          open={addressModalState.isOpen}
          addressId={addressModalState.addressId ?? ''}
          mode={addressModalState.mode}
          orgId={orgId}
          orgCountry={addressesState?.defaultCountry}
          handleClose={handleCloseModal}
          onAddAddress={addAddress}
          updateAddress={updateAddress}
        />
      )}
      {/* todo @implement OrganizationAddressesTable */}
      {/* <OrganizationAddressesTable
        addresses={addressesState.addresses}
        isLoading={addressesState.isLoading}
        handleDeleteAddress={deleteAddress}
        orgId={orgId}
        handleEditAddress={handleEditAddress}
      /> */}
    </div>
  );
};

export default OrganizationAddressesContainer;
