import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';

const drawerWidth = 360;

const formPageSx = {
  width: {
    xl: `calc(100% - ${drawerWidth}px)`,
    lg: `calc(100% - 150px)`,
    md: '100%'
  }
};

export const FormPageContainer: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box {...rest} sx={formPageSx}>
      {children}
    </Box>
  );
};
