'use client';
import { apiService, initialPaymentPlatformFormValues, getMergedFormValues } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { PaymentPlatformValues, FormMode } from '@efacity/common';

export const usePaymentPlatformFormValues = (paymentAccountId: string, formMode: FormMode) => {
  const snackbarProps = useSnackbar();

  const [paymentAccountFormState, setPaymentAccountFormState] = useState({
    paymentAccountFormValue: initialPaymentPlatformFormValues,
    isLoading: false
  });

  useEffect(() => {
    const getStripeAccountById = async () => {
      setPaymentAccountFormState({
        paymentAccountFormValue: initialPaymentPlatformFormValues,
        isLoading: true
      });

      try {
        const { data } = await apiService.get<PaymentPlatformValues>(`/paymentPlatforms/account/${paymentAccountId}`);

        setPaymentAccountFormState((paymentAccountFormState) => ({
          ...paymentAccountFormState,
          paymentAccountFormValue: getMergedFormValues(initialPaymentPlatformFormValues, data),
          isLoading: false
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.message || 'Failed to get Payment Accounts.', true);
        setPaymentAccountFormState({
          paymentAccountFormValue: initialPaymentPlatformFormValues,
          isLoading: true
        });
      }
    };
    if (formMode === FormMode.Add) {
      setPaymentAccountFormState({
        paymentAccountFormValue: initialPaymentPlatformFormValues,
        isLoading: false
      });
    } else {
      getStripeAccountById();
    }
  }, [snackbarProps, paymentAccountId, formMode]);

  return {
    paymentAccountFormState: paymentAccountFormState
  };
};
