import { Button, Dialog, DialogContent, DialogProps, Grid, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CommonDatePicker, LinearProgressWithMessage } from '@efacity/frontend-shared';
import { ActivityTypes, PublicAttendanceInterface, activityTypesMapper, Attendance } from '@efacity/common';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface PublicAttendanceSelector {
  attendanceDate: Date;
  type: ActivityTypes | '';
  locationId: string;
  sessionId: string;
}
export const publicAttendanceSelectorInitialValues: PublicAttendanceSelector = {
  attendanceDate: new Date(),
  type: '',
  locationId: '',
  sessionId: ''
};
export interface StartPublicAttendanceModalProps {
  open: boolean;
  dayAttendance: Partial<Attendance>[];
  startDate: Date;
  getPublicAttendancePage: (date: Date, publicAttendanceInterface: PublicAttendanceInterface) => void;
  getAttendanceForDate;
  onClose: () => void;
  isLoading: boolean;
  progressMessage: string;
}

const StartPublicAttendanceModal: React.FC<DialogProps & StartPublicAttendanceModalProps> = ({
  open,
  dayAttendance,
  startDate,
  getPublicAttendancePage,
  getAttendanceForDate,
  onClose,
  isLoading,
  progressMessage
}) => {
  const activityTypes = Array.from(new Set(dayAttendance.map((attendance) => attendance.type)));
  const [publicAttendanceSelector, setPublicAttendanceSelector] = useState<PublicAttendanceSelector>({
    ...publicAttendanceSelectorInitialValues,
    attendanceDate: startDate
  });

  useEffect(() => {
    getAttendanceForDate(publicAttendanceSelector.attendanceDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicAttendanceSelector.attendanceDate]);

  const setPublicAttendanceDate = (date: Date) => {
    setPublicAttendanceSelector({ ...publicAttendanceSelectorInitialValues, attendanceDate: date });
  };
  const onActivityTypeSelect = (event) => {
    const selectedType = event.target.value as ActivityTypes;
    setPublicAttendanceSelector({ ...publicAttendanceSelector, type: selectedType, locationId: '', sessionId: '' });
  };
  const onLocationSelect = (event) => {
    setPublicAttendanceSelector({ ...publicAttendanceSelector, locationId: event.target.value, sessionId: '' });
  };
  const onSessionSelect = (event) => {
    setPublicAttendanceSelector({ ...publicAttendanceSelector, sessionId: event.target.value });
  };
  const gotoAttendanceClick = () => {
    const publicAttendanceInterface: PublicAttendanceInterface = {
      type: publicAttendanceSelector.type as ActivityTypes
    };
    if (publicAttendanceSelector.locationId) {
      publicAttendanceInterface.locationId = publicAttendanceSelector.locationId;
    }
    if (publicAttendanceSelector.sessionId) {
      publicAttendanceInterface.sessionId = publicAttendanceSelector.sessionId;
    }
    getPublicAttendancePage(publicAttendanceSelector.attendanceDate, publicAttendanceInterface);
  };
  const filteredByTypeSessions = publicAttendanceSelector.type
    ? dayAttendance.filter((attendance) => attendance.type === publicAttendanceSelector.type)
    : [];

  const locations = filteredByTypeSessions
    .map((attendance) => ({ _id: attendance.location._id, name: attendance.location.name }))
    .filter((location, index, self) => self.findIndex((l) => l._id === location._id) === index);
  const filteredByLocationAttendance = publicAttendanceSelector.locationId
    ? filteredByTypeSessions
        .filter((session) => session.location._id === publicAttendanceSelector.locationId)
        .filter((session, index, self) => self.findIndex((s) => s._id === session._id) === index)
    : filteredByTypeSessions;
  const filteredByLocationAttendanceSessions = filteredByLocationAttendance
    .map((attendance) => ({
      sessionId: attendance.sessionId,
      sessionName: attendance.sessionName
    }))
    .filter((session, index, self) => self.findIndex((s) => s.sessionId === session.sessionId) === index);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <ModalDialogTitle text="Public Attendance" onClose={onClose} />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
            <CommonDatePicker
              value={publicAttendanceSelector.attendanceDate}
              onChange={setPublicAttendanceDate}
              id="fromDateFilter"
              label="Date"
              error={false}
              disabled={isLoading}
            />
          </Grid>

          {isLoading ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <LinearProgressWithMessage message={progressMessage} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <TextField
                  size="small"
                  label="Select Activity Type *"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={onActivityTypeSelect}
                  defaultValue={''}
                  data-testid="public-attendance-type-selector"
                >
                  {activityTypes.map((activityType, index) => (
                    <MenuItem key={`key-${index}`} value={activityType}>
                      {activityTypesMapper[activityType]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <TextField
                  size="small"
                  label="Select Location"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={onLocationSelect}
                  defaultValue={''}
                  disabled={filteredByTypeSessions.length === 0}
                  data-testid="public-attendance-location-selector"
                >
                  {locations.map((location) => (
                    <MenuItem key={location._id} value={location._id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <TextField
                  size="small"
                  label="Select Session"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={onSessionSelect}
                  defaultValue={''}
                  disabled={filteredByTypeSessions.length === 0}
                  data-testid="public-attendance-session-selector"
                >
                  {filteredByLocationAttendanceSessions.map((session, index) => (
                    <MenuItem key={`sessionKey${index}-${session.sessionId}`} value={session.sessionId}>
                      {session.sessionName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={gotoAttendanceClick}
                  data-testid="get-camp-public-attendance-page"
                  style={{ width: 290, marginLeft: 5, marginRight: 2, marginBottom: 10, whiteSpace: 'nowrap' }}
                  disabled={filteredByTypeSessions.length === 0}
                >
                  Goto Public Attendance
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StartPublicAttendanceModal;
