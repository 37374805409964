import React, { ChangeEvent, FC, useState } from 'react';
import UploadFileButton from '../UploadFileButton/UploadFileButton';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import ImagePreview from '../ImagePreview/ImagePreview';
import { acceptableMimeTypes, allowedFileSizeInBytes, maxAllowedSizeWithoutCrop } from '@efacity/common';
import CropImageModal from '../MediaUploadContainer/CropImageModal';
import { haveSameProportions } from '../../utils/haveSameProportions';

export interface ImageCropperProps {
  id: string;
  imageSrc?: string;
  onCropFinished: (image: Blob) => void;
  onError?: (message: string) => void;
  imageHeight: number;
  imageWidth: number;
  loading?: boolean;
  disabled?: boolean;
  // by default onChange event for input type file won't work twice
  // if file is the same
  allowSameImageSelection?: boolean;
  uploadLabel?: string;
  previewWidth?: number;
  previewHeight?: number;
  renderPreview?: boolean;
}

export interface ImageState {
  file: File | null;
  cropperPreview: string;
  isLoading: boolean;
}

export const ImageCropper: FC<ImageCropperProps> = ({
  id,
  disabled,
  imageSrc,
  onCropFinished,
  onError,
  imageHeight,
  imageWidth,
  loading,
  allowSameImageSelection = true,
  uploadLabel,
  previewWidth,
  previewHeight,
  renderPreview = true
}) => {
  const [imageState, setImageState] = useState<ImageState>({
    file: null,
    cropperPreview: '',
    isLoading: false
  });
  const [cropModalOpened, setCropModalOpened] = useState(false);

  const onImageCropError = (errorMessage: string) => {
    setCropModalOpened(false);
    onError(errorMessage);
  };

  const handleImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    let file: File;
    if (target.files && target.files.length > 0) {
      file = target.files[0];
    } else {
      return;
    }

    if (file.size > allowedFileSizeInBytes) {
      return onError && onError(`Image size should be up to ${allowedFileSizeInBytes / Math.pow(2, 20)} mb`);
    }
    const reader = new FileReader();
    reader.onloadend = function (loadedFile) {
      setImageState({
        file,
        cropperPreview: reader.result as string,
        isLoading: false
      });

      const image = new Image();
      image.src = loadedFile.target.result as string;
      image.onload = () => {
        if (
          file.size < maxAllowedSizeWithoutCrop &&
          haveSameProportions(image, { width: imageWidth, height: imageHeight })
        ) {
          // do not open image cropper modal
          // if image dimensions are proportionate the recommended dimensions
          // and use the uploaded file directly
          onCropFinished(file);
          return;
        }
        setCropModalOpened(true);
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    if (allowSameImageSelection) {
      event.target.value = '';
    }
  };

  const onCropImage = async (croppedImage: Blob) => {
    setCropModalOpened(false);
    onCropFinished(croppedImage);
  };

  const imagePreviewWidth = previewWidth || imageWidth;
  const imagePreviewHeight = previewHeight || imageHeight;

  const imagePreview = (
    <Box>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={imagePreviewWidth}
          height={imagePreviewHeight}
          data-testid="loadingIndicator"
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderPreview && (
            <ImagePreview src={imageSrc} imageWidth={imagePreviewWidth} imageHeight={imagePreviewHeight} />
          )}
        </div>
      )}
      <Box sx={{ margin: 1 }}>
        {uploadLabel || 'Upload Picture'} (recommended size {`${imageWidth}W x ${imageHeight}H px`})
      </Box>
    </Box>
  );

  return (
    <div>
      <UploadFileButton
        id={id}
        disabled={disabled}
        onFilesSelected={handleImageSelect}
        style={renderPreview ? { marginTop: 10 } : {}}
        acceptableMimeTypes={acceptableMimeTypes}
        sizeLimitInBytes={allowedFileSizeInBytes}
        oneLineDisplay={!renderPreview}
      >
        {imagePreview}
      </UploadFileButton>
      <CropImageModal
        open={cropModalOpened}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        cropperPreview={imageState.cropperPreview}
        onCropFinished={onCropImage}
        onClose={() => {
          setCropModalOpened(false);
        }}
        onCropError={onImageCropError}
      />
    </div>
  );
};

export default ImageCropper;
