import { getNavigationItems, getNavigationItemsBase } from '@efacity/react-next-sc';
import { AuthenticatedUserInfo } from '@efacity/common';
import { useMemo } from 'react';

export const useLeftNavigationItems = (
  workingAccessId: string,
  allowedCreateActivity: boolean,
  allowedCreateSession: boolean,
  enrollmentRequired: boolean,
  startRegistrationURL: string | null,
  user: AuthenticatedUserInfo
) => {
  return useMemo(() => {
    const navigationItemsBase = getNavigationItemsBase({
      allowedCreateActivity,
      allowedCreateSession,
      enrollmentRequired,
      userId: user._id
    });

    return getNavigationItems(navigationItemsBase, workingAccessId, user.adminAccesses || [], user.roles);
  }, [workingAccessId, allowedCreateActivity, allowedCreateSession, enrollmentRequired, user]);
};
