import { useCallback, useEffect, useState } from 'react';
import { AdministratorFullInfo } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { getEmailsArray, isEmptyObject, Messages, Organization, OrganizationWithAdmins } from '@efacity/common';
import {
  initialOrganizationSettingsFormValues,
  OrganizationSettingsWithAdministratorsFormValues
} from '../organizationSettingsForm.utils';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import type { ErrorOption, FieldPath } from 'react-hook-form';
import { apiService } from '@efacity/react-next-sc';

export const useOrganizationSettingsLoader = (orgId: string, orgIdUrl: string, createOrgUrl?: string) => {
  const snackbarProps = useSnackbar();

  const [organizationSettingsState, setOrganizationSettingsState] = useState<{
    organizationSettings: Organization | null;
    administrators: AdministratorFullInfo[];
    isLoading: boolean;
  }>({
    organizationSettings: null,
    administrators: [],
    isLoading: true
  });

  const getOrgSettings = useCallback(async () => {
    const setSettings = (organizationWithAdmins: OrganizationWithAdmins | null, isLoading: boolean) => {
      setOrganizationSettingsState((organizationSettingsState) => ({
        ...organizationSettingsState,
        organizationSettings: organizationWithAdmins?.organizationSettings || null,
        administrators: organizationWithAdmins?.administrators || [],
        isLoading: isLoading
      }));
    };

    setSettings(null, true);
    try {
      const { data } = await apiService().get<{
        data: OrganizationWithAdmins;
      }>(orgIdUrl);
      const organizationSettingsWithAdministrators = { ...data.data };

      setSettings(organizationSettingsWithAdministrators, false);
    } catch (error) {
      showNotification(snackbarProps, false, error.message || 'Failed to get organization info.', true);
      setSettings(null, false);
    }
  }, [orgIdUrl, snackbarProps]);

  useEffect(() => {
    if (orgId) {
      getOrgSettings();
    } else {
      setOrganizationSettingsState({
        organizationSettings: initialOrganizationSettingsFormValues,
        administrators: [],
        isLoading: false
      });
    }
  }, [orgId, getOrgSettings]);

  const updateOrgSettings = async (
    formValues: OrganizationSettingsWithAdministratorsFormValues,
    setError: (
      name: FieldPath<OrganizationSettingsWithAdministratorsFormValues>,
      error: ErrorOption,
      options?: { shouldFocus: boolean }
    ) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ): Promise<boolean> => {
    try {
      const organizationSettingsWithAdministrators = { ...formValues };
      const formDataToSend = new FormData();
      formDataToSend.append(
        'organizationSettings',
        JSON.stringify({
          ...organizationSettingsWithAdministrators.organizationSettings,
          additionalNotificationEmails: getEmailsArray(
            organizationSettingsWithAdministrators.organizationSettings.additionalNotificationEmails || ''
          )
        })
      );
      formDataToSend.append('administrators', JSON.stringify(organizationSettingsWithAdministrators.administrators));

      if (logoImage) {
        formDataToSend.append('logoImage', logoImage);
      }

      if (mobileLogoImage) {
        formDataToSend.append('mobileLogoImage', mobileLogoImage);
      }

      const { data } = await apiService().patch<{ message: string }, FormData>(orgIdUrl, formDataToSend);
      showNotification(snackbarProps, true, data.message);
      return true;
    } catch (error) {
      const validationErrors = error.response?.data?.validationErrors;
      if (typeof validationErrors === 'object' && !isEmptyObject(validationErrors)) {
        addServerErrors<OrganizationSettingsWithAdministratorsFormValues>(validationErrors, setError);
      } else {
        showNotification(
          snackbarProps,
          false,
          error.response?.data?.message || Messages.FailedToUpdateOrganization,
          true
        );
      }
      return false;
    }
  };

  const createOrganization = async (
    formValues: OrganizationSettingsWithAdministratorsFormValues,
    setError: (
      name: FieldPath<OrganizationSettingsWithAdministratorsFormValues>,
      error: ErrorOption,
      options?: { shouldFocus: boolean }
    ) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ): Promise<boolean> => {
    try {
      const organizationSettingsWithAdministrators = { ...formValues };

      const formDataToSend = new FormData();
      formDataToSend.append(
        'organizationSettings',
        JSON.stringify({
          ...organizationSettingsWithAdministrators.organizationSettings,
          additionalNotificationEmails: getEmailsArray(
            organizationSettingsWithAdministrators.organizationSettings.additionalNotificationEmails || ''
          )
        })
      );
      formDataToSend.append('administrators', JSON.stringify(organizationSettingsWithAdministrators.administrators));

      if (logoImage) {
        formDataToSend.append('logoImage', logoImage);
      }

      if (mobileLogoImage) {
        formDataToSend.append('mobileLogoImage', mobileLogoImage);
      }

      const { data } = await apiService().post<{ message: string }, FormData>(createOrgUrl || '', formDataToSend);
      showNotification(snackbarProps, true, data.message);
      return true;
    } catch (error) {
      const validationErrors = error.response?.data?.validationErrors;
      if (typeof validationErrors === 'object' && !isEmptyObject(validationErrors)) {
        addServerErrors<OrganizationSettingsWithAdministratorsFormValues>(validationErrors, setError);
      } else {
        showNotification(
          snackbarProps,
          false,
          error.response?.data?.message || Messages.FailedToCreateOrganization,
          true
        );
      }
      return false;
    }
  };

  function deleteOrgAdmin(adminId: string, url: string) {
    return apiService()
      .delete<{ message: string }>(url)
      .then(({ data }) => {
        showNotification(snackbarProps, true, data.message, false);
        const newAdmins = organizationSettingsState.administrators.filter((admin) => admin._id !== adminId);
        setOrganizationSettingsState((state) => ({
          ...state,
          administrators: newAdmins,
          isLoading: false
        }));
      })
      .catch((error) => {
        showNotification(snackbarProps, false, error.response.data.message || 'Failed to delete administrator', true);
      });
  }

  return [
    {
      organizationSettingsState: organizationSettingsState,
      setOrganizationSettingsState
    },
    {
      createOrganization,
      updateOrgSettings,
      deleteOrgAdmin
    }
  ];
};
