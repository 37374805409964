import type { Access, AdminAccess } from './Access';
import type { OrganizationSimple } from './OrganizationSimple';
import type { Roles } from './Roles';
import type { ShoppingCart } from './ShoppingCart';

export class AuthenticatedUserInfo {
  constructor(
    public _id: string,
    public email: string,
    public fullName: string,
    public defaultPhone: string,
    public defaultPhoneCountry: string,
    public dateOfBirth: {
      year: string;
      month: string;
      day: string;
    },
    public address: {
      address: string;
      country: string;
      city: string;
      zip: string;
      state: string;
    },
    public roles: Roles[],
    public access: Access[],
    public callByName?: string,
    public schoolId?: string,
    public googleId?: string,
    public adminAccesses?: AdminAccess[]
  ) {}
}

export class ExtraCenterInfo {
  constructor(public allowedCreateActivity: boolean) {}
}

export class ExtraAuthInfo {
  constructor(
    public center: ExtraCenterInfo,
    public shoppingCart: ShoppingCart | null,
    public startRegistrationURL: string | null,
    public message?: string
  ) {}
}

export enum AuthStatus {
  Checking,
  Unauthenticated,
  Authenticated
}

export interface AuthState {
  isAuthenticated: AuthStatus;
  user: AuthenticatedUserInfo;
  extraAuthInfo: ExtraAuthInfo;
  workingAccessId: string | undefined;
  workingOrganizationName: string | undefined;
  loading: boolean;
  isQueryingAccessUrl: boolean;
  isLogout: boolean;
  error?: string;
}

export interface AuthUserResponse {
  user: AuthenticatedUserInfo;
  extraAuthInfo: ExtraAuthInfo;
  organization: Pick<OrganizationSimple, '_id' | 'name'>;
}
