import { Box, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/HighlightOff';
import React, { ChangeEvent, ReactElement } from 'react';
import { FormGroupTitle, sharedFormSx } from '@efacity/frontend-next-shared/forms/server';
import { Administrator, AdministratorFullInfo } from '@efacity/frontend-shared';
import { Messages } from '@efacity/common';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormTextInput } from '@efacity/react-hook-form-mui';
import { AddAdminButton } from './AddAdminButton';

interface AdminFormProps {
  pathToFormObject: string;
  administratorsList?: AdministratorFullInfo[];
  formTitle: string;
  additionalHeaderField?: ReactElement;
  isFormVisible?: boolean;
}

const isValidEmail = (email) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const AdminForm: React.FC<AdminFormProps> = ({ administratorsList = [], formTitle, isFormVisible = true }) => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'administrators'
  });

  const validateEmail = (name, value) => {
    methods.clearErrors(name);
    if (!value) {
      return methods.setError(name, {
        type: 'required',
        message: Messages.EmailRequired
      });
    }
    if (!isValidEmail(value))
      return methods.setError(name, {
        type: 'email',
        message: Messages.InvalidEmail
      });

    const isAlreadyInList = administratorsList.some((admin) => admin.email === value);
    if (isAlreadyInList) {
      return methods.setError(name, {
        type: 'duplicate',
        message: 'This administrator is already on the list'
      });
    }
  };

  const onAddAdminFieldsRow = () => () => {
    append(new Administrator('', ''));
  };

  const onRemoveAdminFieldsRow = (index: number) => () => {
    remove(index);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <FormGroupTitle>{formTitle}</FormGroupTitle>
        <AddAdminButton onClick={onAddAdminFieldsRow} />
      </Box>

      {isFormVisible && (
        <>
          {fields.map((administrator, index: number) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                key={index}
                marginBottom={2}
                sx={sharedFormSx.inlineInputsGroup}
                data-testid="schoolAdminInputsRow"
              >
                <Box sx={sharedFormSx.inlineField}>
                  <FormTextInput
                    label="Full Name"
                    id={`administrators.${index}.fullName`}
                    name={`administrators.${index}.fullName`}
                    key={index}
                    required
                  />
                </Box>
                <Box sx={sharedFormSx.emailInput} style={{ flexGrow: 1 }}>
                  <FormTextInput
                    label="Email (used for login)"
                    id={`administrators.${index}.email`}
                    name={`administrators.${index}.email`}
                    key={index}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onValueChange={(name, event: ChangeEvent<HTMLInputElement>) =>
                      validateEmail(name, event.target.value)
                    }
                    required
                  />
                </Box>

                {administratorsList?.length > 1 && (
                  <IconButton
                    onClick={onRemoveAdminFieldsRow(index)}
                    style={{ alignSelf: 'center', marginTop: '-4px' }}
                    sx={sharedFormSx.deleteButtonIcon}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};
