import React, { useEffect } from 'react';
import { PATHS, toPath } from '@efacity/routing';
import PageLinkWithCopier from '../../../components/PageLinkWithCopier/PageLinkWithCopier';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { defaultRHFSetValueOptions, FormCurrencyInput } from '@efacity/react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  organizationEnrollmentFormValidationSchema,
  OrganizationEnrollmentsFormValues
} from './organizationEnrollmentFormValues';
import { useOrganizationEnrollmentSettingsLoader } from './useOrganizationEnrollmentSettingsLoader';
import { FormPageContainer } from '@efacity/frontend-next-shared/forms/server';
import { WithLoading, FormGroupDivider, CircularProgressButton } from '@efacity/frontend-shared';
import { useNavigate } from 'react-router-dom';
import { CurrencyCode } from '@efacity/common';

export interface OrganizationEnrollmentsContainerProps {
  orgId: string;
}
export const OrganizationEnrollmentSettingsContainer: React.FC<OrganizationEnrollmentsContainerProps> = ({ orgId }) => {
  const navigate = useNavigate();
  const { organizationEnrollmentSettingsState, updateOrganizationEnrollmentSettings } =
    useOrganizationEnrollmentSettingsLoader(orgId);

  const methods = useForm<OrganizationEnrollmentsFormValues>({
    defaultValues: organizationEnrollmentSettingsState.organizationEnrollments,
    resolver: yupResolver(organizationEnrollmentFormValidationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    methods.reset(organizationEnrollmentSettingsState.organizationEnrollments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(organizationEnrollmentSettingsState.organizationEnrollments)]);

  const submitForm = async (values: OrganizationEnrollmentsFormValues) => {
    await updateOrganizationEnrollmentSettings(values, methods.setError);
    navigate(0);
  };

  return (
    <Box style={{ marginTop: 20 }}>
      <PageLinkWithCopier
        name="Enrollment page"
        path={toPath(PATHS.enrollment, { orgId: orgId })}
        tooltipText="Copy Enrollment page link to share"
      />
      <FormPageContainer>
        <FormGroupDivider marginY={20} />
        <WithLoading
          isLoading={organizationEnrollmentSettingsState.isLoading}
          message={'Loading enrollment settings...'}
        >
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(submitForm)}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={methods.watch('enrollmentRequired')}
                          name={'enrollmentRequired'}
                          color="primary"
                          onChange={() => {
                            methods.setValue(
                              'enrollmentRequired',
                              !methods.getValues('enrollmentRequired'),
                              defaultRHFSetValueOptions
                            );
                          }}
                        />
                      }
                      label="Student Enrollment required"
                    />
                  </div>
                </Grid>
                {methods.watch('enrollmentRequired') && (
                  <Grid item xs={12} sm={6}>
                    <FormCurrencyInput
                      name={'enrollmentPrice'}
                      label="Enrollment Price *"
                      data-testid="organization.form.enrollmentPrice"
                      value={methods.getValues('enrollmentPrice') / 100}
                      currency={methods.getValues('currency') as CurrencyCode}
                    />
                  </Grid>
                )}
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
                  <CircularProgressButton progress={organizationEnrollmentSettingsState.isSubmitting} color={'primary'}>
                    Save
                  </CircularProgressButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </WithLoading>
      </FormPageContainer>
    </Box>
  );
};
