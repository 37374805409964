import React from 'react';
import { OrganizationBasicForm } from './OrganizationBasicForm';
import { AdministratorFullInfo, FormGroupDivider, getNestedFormProperty } from '@efacity/frontend-shared';
import { TaxesForm } from './TaxesForm';
import { AdminForm } from './AdminForm';
import { AdministratorsList } from './AdministratorsList';
import { AdministratorInfo, CurrencyCode, FormMode, PaymentAcceptanceMethod } from '@efacity/common';
import {
  ConnectModelSelector,
  defaultRHFSetValueOptions,
  FormCurrencyInput,
  FormTextInput,
  OrganizationStatusSelector,
  PaymentAcceptanceMethodSelector
} from '@efacity/react-hook-form-mui';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { AuthenticatedUserInfo } from '@efacity/common';
import { useFormContext } from 'react-hook-form';

export interface OrganizationSettingsFormGroupProps {
  orgId?: string;
  authenticatedUser: AuthenticatedUserInfo;
  isSiteAdmin?: boolean;
  mode: FormMode;
  setLogoImgBlob: (logoImg: Blob) => void;
  setMobileLogoImgBlob: (mobileLogoImg: Blob) => void;
  loading: boolean;
  deletingOrgAdmin: boolean;
  administrators: AdministratorInfo[];
  handleDeleteAdmin: (adminId: string) => void;
  canDeleteAdmin: (adminInfo: AdministratorFullInfo, authenticatedUser: AuthenticatedUserInfo, id?: string) => boolean;
  pathToFormObject: string;
}

export const OrganizationSettingsFormGroup: React.FC<OrganizationSettingsFormGroupProps> = ({
  orgId,
  authenticatedUser,
  isSiteAdmin = false,
  mode,
  setLogoImgBlob,
  setMobileLogoImgBlob,
  loading,
  deletingOrgAdmin,
  administrators,
  handleDeleteAdmin,
  canDeleteAdmin,
  pathToFormObject
}) => {
  const showAdminsList = isSiteAdmin || mode === FormMode.Add;
  const { watch, setValue, getValues } = useFormContext();

  return (
    <>
      {isSiteAdmin && (
        <>
          <OrganizationStatusSelector name={`${pathToFormObject}.status`} required />
          <FormGroupDivider marginY={20} />
        </>
      )}
      <OrganizationBasicForm
        orgId={orgId}
        mode={mode}
        setLogoImgBlob={setLogoImgBlob}
        setMobileLogoImgBlob={setMobileLogoImgBlob}
        pathToFormObject={pathToFormObject}
        loading={loading}
      />
      {isSiteAdmin && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch(getNestedFormProperty('enrollmentRequired', pathToFormObject))}
                  name={getNestedFormProperty('enrollmentRequired', pathToFormObject)}
                  color="primary"
                  onChange={() => {
                    setValue(
                      getNestedFormProperty('enrollmentRequired', pathToFormObject),
                      !getValues(getNestedFormProperty('enrollmentRequired', pathToFormObject)),
                      defaultRHFSetValueOptions
                    );
                  }}
                />
              }
              label="Student Enrollment required"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormCurrencyInput
              name={getNestedFormProperty('enrollmentPrice', pathToFormObject)}
              label="Enrollment Price *"
              data-testid="organization.form.enrollmentPrice"
              value={getValues(getNestedFormProperty('enrollmentPrice', pathToFormObject)) / 100}
              currency={getValues(getNestedFormProperty('defaultCurrency', pathToFormObject)) as CurrencyCode}
            />
          </Grid>
        </Grid>
      )}

      <FormGroupDivider marginY={20} />
      <TaxesForm pathToFormObject={pathToFormObject} />
      <FormGroupDivider marginY={20} />
      {isSiteAdmin && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <PaymentAcceptanceMethodSelector
                name={getNestedFormProperty('paymentAcceptanceMethod', pathToFormObject)}
              />
            </Grid>
            {(watch(
              getNestedFormProperty('paymentAcceptanceMethod', pathToFormObject)
            ) as unknown as PaymentAcceptanceMethod) === PaymentAcceptanceMethod.Efacity && (
              <>
                <Grid item xs={12} sm={4}>
                  <ConnectModelSelector name={getNestedFormProperty('connectModel', pathToFormObject)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormTextInput
                    label="Efacity Fee *"
                    name={getNestedFormProperty('efacityFee', pathToFormObject)}
                    type="number"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <FormGroupDivider marginY={20} />
        </>
      )}

      {showAdminsList && (
        <>
          <AdminForm
            administratorsList={administrators}
            pathToFormObject="administrators"
            formTitle={mode === FormMode.Edit ? 'Admins' : 'Admin'}
          />
          <AdministratorsList
            loading={deletingOrgAdmin}
            canDeleteAdmin={(admin) => canDeleteAdmin(admin, authenticatedUser, orgId)}
            administratorsList={administrators}
            onDeleteAdministrator={handleDeleteAdmin}
          />
        </>
      )}
    </>
  );
};
