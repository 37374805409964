import { EnhancedColumn } from '../../components/Table/Table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import {
  ActivityTypes,
  activityTypesMapper,
  convertUtcToZonedTime,
  DiscountClass,
  DiscountType,
  moneyWithCurrencyCodeFormatter,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const getActivityTypesList = (activityTypes: ActivityTypes[]) => {
  if (!(activityTypes?.length > 0)) return 'None';
  if (Object.values(ActivityTypes).every((type) => activityTypes.includes(type))) return 'All';

  return activityTypes.map((type) => activityTypesMapper[type]).join(', ');
};

const useDiscountsTableColumns = (accessId, timeZone: string, additionalColumns: EnhancedColumn<any>[] = []) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Description in the invoice line',
        accessor: 'description',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row, value }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editDiscount, { orgId: accessId, id: row.original._id })}
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: () => <div>Discount</div>,
        accessor: 'typeDefinition',
        disableFilters: true,
        headerStyles: {
          textAlign: 'right'
        },
        columnStyles: {
          textAlign: 'right'
        },
        Cell: ({ row, value: typeDefinition }) => {
          return (
            <>
              {typeDefinition.discountType === DiscountType.Amount &&
                moneyWithCurrencyCodeFormatter(typeDefinition.discountAmount, row.original.currencyCode)}
              {typeDefinition.discountType === DiscountType.Percentage && `${typeDefinition.discountPercentage} %`}
            </>
          );
        }
      },
      {
        Header: 'Code',
        accessor: 'code',
        disableFilters: true,
        disableSortBy: true,
        headerStyles: {
          textAlign: 'right'
        },
        columnStyles: { textAlign: 'right', whiteSpace: 'nowrap' },
        Cell: ({ row, value }) => {
          if (row.original.classDefinition.discountClass !== DiscountClass.Coupon) return null;
          return <div>{value}</div>;
        }
      },
      {
        Header: ' ',
        disableFilters: true,
        columnStyles: {
          textAlign: 'left'
        },
        Cell: ({ row }) => {
          if (row.original.classDefinition.discountClass !== DiscountClass.Coupon) return null;
          return (
            <Button
              onClick={() => copy(row.original.code)}
              color="primary"
              style={{ paddingLeft: 0, margin: 0, minWidth: 0, marginRight: '10px' }}
            >
              <ContentCopyIcon />
            </Button>
          );
        }
      },
      {
        Header: 'Applied to',
        accessor: 'activityTypes',
        disableFilters: true,
        headerStyles: {
          maxWidth: 120,
          width: 120
        },
        Cell: ({ value }) => <span>{getActivityTypesList(value)} </span>
      },
      {
        Header: 'Expires On',
        accessor: 'expiresOn',
        disableFilters: true,
        headerStyles: {
          maxWidth: 120,
          width: 120
        },
        Cell: ({ value }) => <>{formatStringToMonthDayYearDate(convertUtcToZonedTime(value, timeZone).toISOString())}</>
      },

      ...additionalColumns
    ];

    return columns;
  }, [accessId, timeZone, additionalColumns]);
};

export default useDiscountsTableColumns;
