import React, { useEffect } from 'react';
import {
  apiService,
  getFormAsFormData,
  MediaUploadContainer,
  RichTextEditor,
  RichTextEditorLabel
} from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { FormPageContainer } from '@efacity/frontend-next-shared/forms/server';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { CurrencyCode, FormMode, Media, Messages, ValidationErrors } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useProductsLoader } from './useProductsLoader';
import { kitFormValidationSchema, ProductFormValues, ProductPostValues } from './productFormValues';
import { cloneDeep } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  addServerErrors,
  defaultRHFSetValueOptions,
  FormCurrencyInput,
  FormTextInput,
  StudentLevelSelector
} from '@efacity/react-hook-form-mui';
import { Descendant } from 'slate';
import { WithLoading, OrgAdminPaymentLink } from '@efacity/frontend-shared';
import { PageTitle } from '@efacity/react-next-sc';

export interface KitFormProps {
  mode: FormMode;
}

export type ProductIdParamType = Partial<{
  productId: string;
  orgId: string;
}>;

const ProductForm: React.FC<KitFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const snackbarProps = useSnackbar();
  const { orgId, productId } = useParams<ProductIdParamType>();

  const {
    productWithRegionsFormState: { kitFormValues, isLoading, isPaymentAllowed }
  } = useProductsLoader(orgId, productId, mode);

  const methods = useForm<ProductFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(kitFormValidationSchema),
    defaultValues: cloneDeep(kitFormValues),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  const onVideoSelected = (videoUrl: string) => {
    const videoMedia: Media = {
      link: videoUrl
    };
    const mediaArray = methods.getValues('media');
    methods.setValue('media', [...mediaArray, videoMedia], { shouldDirty: true });
  };
  const onDeleteMedia = (index: number) => {
    const mediaArray = methods.getValues('media');
    mediaArray.splice(index, 1);
    methods.setValue('media', mediaArray, { shouldDirty: true });
  };
  const onImageSelected = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    const imageBlobMedia: Media = {
      link: imagePreviewUrl,
      blob: image,
      previewUrl: imagePreviewUrl
    };
    const mediaArray = methods.getValues('media');
    methods.setValue('media', [...mediaArray, imageBlobMedia], { shouldDirty: true });
  };

  const handleCancelClick = () => {
    navigate(toPath(PATHS.products, { orgId: orgId }));
  };

  const handleSubmit = async (
    formValues: ProductFormValues,
    setError: (fieldName: keyof ProductFormValues, error: { type: string; message: string }) => void
  ) => {
    const formValuesToSend = cloneDeep(formValues);
    const actionSuccessful = () => {
      navigate(toPath(PATHS.products, { orgId: orgId }));
    };
    const actionFailure = (errorResponse, message: string) => {
      if (errorResponse.validationErrors) {
        addServerErrors<ProductFormValues>(errorResponse.validationErrors, setError);
      } else {
        showNotification(snackbarProps, false, message, true);
      }
    };

    const blobImages = [];
    const mediaWithLinks = [];
    formValues?.media?.forEach((mediaItem: Media) => {
      if (mediaItem.blob) {
        return blobImages.push(mediaItem.blob);
      }
      return mediaWithLinks.push(mediaItem.link);
    });
    formValuesToSend.media = mediaWithLinks;
    const description = JSON.stringify(formValuesToSend.description);

    const formDataToSend = getFormAsFormData<ProductPostValues>({ ...formValuesToSend, description }, []);
    if (blobImages?.length > 0) {
      blobImages.forEach((blob: Blob) => {
        formDataToSend.append(`blobMedia`, blob);
      });
    }

    if (mode === FormMode.Edit) {
      return apiService
        .patch(`/org/${orgId}/product/${productId}`, formDataToSend)
        .then(() => {
          actionSuccessful();
        })
        .catch((error) => {
          actionFailure(error.response.data, error.response.data.message || Messages.FailedToUpdateKit);
        });
    } else {
      return apiService
        .post<{ message: string }>(`/org/${orgId}/product`, formDataToSend)
        .then(() => actionSuccessful())
        .catch((error) => {
          actionFailure(error.response.data, error.response.data.message || Messages.FailedToCreateKit);
        });
    }
  };

  const submitForm = (values: ProductFormValues) => {
    return handleSubmit(values, methods.setError);
  };

  useEffect(() => {
    methods.reset(kitFormValues);
  }, [methods, kitFormValues]);

  return (
    <div>
      <PageTitle title={mode === FormMode.Edit ? 'Edit Product' : 'Add Product'} underlined />
      <FormPageContainer>
        <WithLoading isLoading={isLoading} message={'Loading Product...'} style={{ width: '50%' }}>
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(submitForm)}>
              <FormTextInput
                label="Name"
                name="name"
                id="name"
                data-testid="product.form.name"
                helperText="Should be unique"
                required
              />

              <MediaUploadContainer
                media={methods.watch('media')}
                onVideoSelected={(videoUrl) => {
                  onVideoSelected(videoUrl);
                }}
                onImageSelected={(croppedImage) => {
                  onImageSelected(croppedImage);
                }}
                onDeleteMedia={(index) => {
                  onDeleteMedia(index);
                }}
              />

              <StudentLevelSelector namePrefix="recommendedFor" label="Recommended for" />

              <div>
                <FormCurrencyInput
                  name="price"
                  id="price"
                  label="Price *"
                  data-testid="product.form.price"
                  value={methods.getValues('price') / 100}
                  currency={methods.getValues('currencyCode') as CurrencyCode}
                  disabled={!isPaymentAllowed}
                  forceErrorMessage={isPaymentAllowed ? null : ValidationErrors.ConnectRequired}
                />
                {!isPaymentAllowed && <OrgAdminPaymentLink orgId={orgId} />}
              </div>

              <div style={{ marginTop: 15, marginBottom: 13 }}>
                <RichTextEditorLabel>Description *</RichTextEditorLabel>
                <RichTextEditor
                  data-testid="product.form.description"
                  value={kitFormValues.description}
                  onChange={(value: Descendant[]) => {
                    methods.setValue('description', value, defaultRHFSetValueOptions);
                  }}
                  editorHeight={200}
                  placeholder={'Product description...'}
                />
              </div>

              <FormTextInput
                label="Inventory Quantity"
                name="inventoryQuantity"
                id="inventoryQuantity"
                type="number"
                data-testid="product.form.inventory.quantity"
                required
              />

              <FormCancelSubmitButtons
                isFormValid={isValid && isDirty}
                mode={mode}
                isSubmitting={isSubmitting}
                onCancel={handleCancelClick}
                isDisabled={isLoading || isSubmitting}
              />
            </form>
          </FormProvider>
        </WithLoading>
      </FormPageContainer>
    </div>
  );
};

export default ProductForm;
