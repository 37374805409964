import { Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ActivityIdName, FormGroupDivider } from '@efacity/frontend-shared';
import { AdministratorInfo, FormMode } from '@efacity/common';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { canDeleteCenterAdmin, OrganizationSettingsFormGroup } from '@efacity/frontend-next-shared/org-settings';
import { FormGroupTitle } from '@efacity/frontend-next-shared/forms/server';
import ActivitiesAllowedToCenterForm from './ActivitiesAllowedToCenter';
import { CenterFormValues } from '../useCenterFormValues';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultRHFSetValueOptions, FormTextInput } from '@efacity/react-hook-form-mui';
import { addCenterFormValidationSchema, editCenterFormValidationSchema } from './centerFormValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenticatedUserInfo } from '@efacity/common';

const centerFormSx = {
  paper: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    maxHeight: '230px',
    overflowY: 'scroll',
    height: '230px'
  },
  activityHeader: {
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h5': {
      margin: 0,
      padding: 0,
      fontSize: 18,
      fontWeight: 400
    }
  }
};

export interface CenterFormProps {
  handleSubmit: (
    formValues: CenterFormValues,
    setError: (name, error, options?) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ) => void;
  mode: FormMode;
  administrators?: AdministratorInfo[];
  orgId?: string;
  centerFormValues: CenterFormValues;
  allActivities: ActivityIdName[];
  onDeleteOrgAdmin?: (adminId: string) => void;
  deletingOrgAdmin?: boolean;
  authenticatedUser: AuthenticatedUserInfo;
  onCancel: () => void;
  loading?: boolean;
}

export const CenterForm: FC<CenterFormProps> = ({
  handleSubmit,
  mode,
  orgId,
  administrators,
  centerFormValues,
  allActivities,
  onDeleteOrgAdmin,
  deletingOrgAdmin,
  authenticatedUser,
  onCancel,
  loading
}) => {
  const pathToFormObject = 'centerSettings';

  const [logoImgBlob, setLogoImgBlob] = useState(null);
  const [mobileLogoImgBlob, setMobileLogoImgBlob] = useState(null);

  const [showSelectedActivities, setShowSelectedActivities] = useState(true);
  const toggleSelectedActivitiesShow = () => {
    setShowSelectedActivities((prev) => !prev);
  };
  const [showSelectedActivitiesDisabled, setShowSelectedActivitiesDisabled] = useState(false);

  const methods = useForm<CenterFormValues>({
    defaultValues: centerFormValues,
    resolver:
      mode === FormMode.Add
        ? yupResolver(addCenterFormValidationSchema(pathToFormObject))
        : yupResolver(editCenterFormValidationSchema(pathToFormObject)),
    mode: 'onBlur'
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  useEffect(
    () => {
      methods.reset(centerFormValues);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [centerFormValues]
  );

  const toggleAllowedCreateActivity = () => {
    methods.setValue(
      'centerSettings.allowedCreateActivity',
      !methods.getValues('centerSettings.allowedCreateActivity'),
      defaultRHFSetValueOptions
    );
  };

  const onSubmit = (formValues: CenterFormValues) => {
    handleSubmit(formValues, methods.setError, logoImgBlob, mobileLogoImgBlob);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <OrganizationSettingsFormGroup
          orgId={orgId}
          authenticatedUser={authenticatedUser}
          mode={mode}
          setLogoImgBlob={setLogoImgBlob}
          setMobileLogoImgBlob={setMobileLogoImgBlob}
          loading={loading}
          deletingOrgAdmin={deletingOrgAdmin}
          administrators={administrators}
          handleDeleteAdmin={onDeleteOrgAdmin}
          canDeleteAdmin={canDeleteCenterAdmin}
          pathToFormObject={pathToFormObject}
        />

        <FormGroupDivider marginY={30} />
        <FormGroupTitle>Activities</FormGroupTitle>

        <FormControlLabel
          control={
            <Checkbox
              checked={methods.watch('centerSettings.allowedCreateActivity')}
              name="centerSettings.allowedCreateActivity"
              onChange={() => toggleAllowedCreateActivity()}
              color="primary"
            />
          }
          label="Allow Activity Creation"
        />

        <Grid container sx={centerFormSx.activityHeader}>
          <Grid item>
            <h5>Allowed Activities:</h5>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSelectedActivities}
                  onChange={toggleSelectedActivitiesShow}
                  disabled={showSelectedActivitiesDisabled}
                  name="showSelectedActivities"
                  color="primary"
                />
              }
              label="Show selected"
            />
          </Grid>
        </Grid>
        <Paper sx={centerFormSx.paper}>
          <ActivitiesAllowedToCenterForm
            allActivities={allActivities}
            showSelectedActivities={showSelectedActivities}
            setShowSelectedActivities={setShowSelectedActivities}
            setShowSelectedActivitiesDisabled={setShowSelectedActivitiesDisabled}
          />
        </Paper>

        <FormGroupDivider marginY={30} />
        <FormTextInput type="number" name="centerSettings.schoolFee" label="School Fee (%)" required />
        <FormGroupDivider marginY={30} />
        <FormTextInput
          name="centerSettings.additionalNotificationEmailsToPrimarySchool"
          label="Registration Notification Emails for School Admin (comma separated)"
        />

        <FormCancelSubmitButtons
          mode={mode}
          isFormValid={isValid}
          isSubmitting={isSubmitting}
          onCancel={onCancel}
          isDisabled={!isDirty}
        />
      </form>
    </FormProvider>
  );
};
