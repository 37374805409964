import React, { useEffect } from 'react';
import { MediaUploadContainer, FormGroupDivider, RichTextEditor } from '@efacity/frontend-shared';
import {
  FormTextInput,
  CountrySelector,
  StateSelector,
  JobPostingLocationTypeSelector,
  JobPostingTypeSelector,
  JobPostingScheduleSelector,
  JobPostingOpenPositionsSelector,
  JobPostingPayBySelector,
  JobPostingRateSelector,
  FormCurrencyInput,
  FormDatePicker
} from '@efacity/react-hook-form-mui';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { CountryCode, CurrencyCode, FormMode, JobPayBy, Media } from '@efacity/common';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { jobPostingFormValidationSchema, JobPostingFormValues } from './jobPostingFormValues';
import { Descendant } from 'slate';

export interface JobPostingFormProps {
  initialFormValues: JobPostingFormValues;
  handleSubmit: (
    values: JobPostingFormValues,
    setError: (fieldName: keyof JobPostingFormValues, error: { type: string; message: string }) => void
  ) => void;
  handleCancel: () => void;
  formMode?: FormMode;
}

const JobPostingForm: React.FC<JobPostingFormProps> = ({ initialFormValues, handleSubmit, handleCancel, formMode }) => {
  const methods = useForm<JobPostingFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(jobPostingFormValidationSchema),
    defaultValues: initialFormValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid, isDirty, errors } = methods.formState;

  useEffect(() => {
    methods.reset(initialFormValues);
  }, [methods, initialFormValues]);

  const { fields, append, remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'media' // unique name for your Field Array
  });

  const triggerStateValidation = async () => {
    await methods.trigger('country');
    await methods.trigger('state');
  };

  const onVideoSelected = (videoUrl: string) => {
    const videoMedia: Media = {
      link: videoUrl
    };
    append(videoMedia);
  };
  const onDeleteMedia = (index: number) => {
    remove(index);
  };
  const onImageSelected = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    const imageBlobMedia: Media = {
      link: imagePreviewUrl,
      blob: image,
      previewUrl: imagePreviewUrl
    };
    append(imageBlobMedia);
  };

  const onRichTextEditorChange = (value: Descendant[]) => {
    methods.setValue('description', value, { shouldTouch: true, shouldDirty: true });
  };

  const submitForm = (values: JobPostingFormValues) => {
    handleSubmit(values, methods.setError);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(submitForm)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormTextInput label="Title" name="title" required={true} />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>Location</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <JobPostingLocationTypeSelector name="locationType" required={true} />
          </Grid>
          <Grid item xs={9} md={3}>
            <CountrySelector name="country" onValueChange={triggerStateValidation} required={true} />
          </Grid>
          <Grid item xs={3} md={2}>
            <StateSelector name="state" countryCode={methods.watch('country') as CountryCode} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextInput label="City" name="city" required={true} />
          </Grid>
          <Grid item xs={12}>
            <FormGroupDivider marginY={10} />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobPostingTypeSelector name="jobType" required={true} />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobPostingScheduleSelector name="jobSchedule" required={true} />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobPostingOpenPositionsSelector name="jobOpenPositions" required={true} />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobPostingPayBySelector name="jobPayBy" required={true} />
          </Grid>
          {methods.watch('jobPayBy') === JobPayBy.Amount ? (
            <Grid item xs={12} md={4}>
              <FormCurrencyInput
                name="minimumRange"
                label="Amount"
                currency={methods.watch('currency') as CurrencyCode}
                required={true}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={6} md={2}>
                <FormCurrencyInput
                  name={'minimumRange'}
                  label={'Minimum'}
                  currency={methods.watch('currency') as CurrencyCode}
                  required={true}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <FormCurrencyInput
                  name={'maximumRange'}
                  label={'Maximum'}
                  currency={methods.watch('currency') as CurrencyCode}
                  required={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={4}>
            <JobPostingRateSelector name={'jobRate'} required={true} />
          </Grid>
          <Grid item xs={12}>
            <FormGroupDivider marginY={10} />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              <RichTextEditor
                value={initialFormValues.description}
                onChange={onRichTextEditorChange}
                editorHeight={300}
                placeholder={'Enter job description here...'}
              />
              {errors?.description && <FormHelperText error>{errors?.description.toString()}</FormHelperText>}
            </div>
          </Grid>
          <Grid item xs={12}>
            <MediaUploadContainer
              media={fields}
              onVideoSelected={(videoUrl) => {
                onVideoSelected(videoUrl);
              }}
              onImageSelected={(croppedImage) => {
                onImageSelected(croppedImage);
              }}
              onDeleteMedia={(index) => {
                onDeleteMedia(index);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormDatePicker name={'expiresOn'} id={'expiresOn'} label={'Expires On'} />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" marginTop={2}>
          <FormCancelSubmitButtons
            mode={formMode}
            onCancel={handleCancel}
            showCancel={true}
            isDisabled={isSubmitting || !isDirty}
            isSubmitting={isSubmitting}
            isFormValid={isValid}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default JobPostingForm;
