import { customTheme } from '@efacity/frontend-shared';
import { AuthContext, initialAuthState } from '@efacity/react-next-sc';
import { AuthState } from '@efacity/common';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routing } from './components/Routes/Routing';
import { setSentryUser } from '@efacity/frontend-next/sentry';

function App() {
  document.title = 'Efacity';
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);
  useEffect(() => {
    setSentryUser(authState.isAuthenticated ? authState?.user : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isAuthenticated]);

  return (
    <ThemeProvider theme={customTheme}>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider maxSnack={20}>
              <>
                <CssBaseline />
                <Routing />
              </>
            </SnackbarProvider>
          </StyledEngineProvider>
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
