import * as Sentry from '@sentry/nextjs';
import { AppEnv, SENTRY_IGNORE_ERRORS } from '@efacity/common';

export function initSentryReplayIntegration(environment: AppEnv, sentryDsn: string, integrations = null) {
  if (environment === AppEnv.PROD || environment === AppEnv.TEST) {
    if (!sentryDsn) throw new Error('Sentry DSN not set');

    Sentry.init({
      dsn: sentryDsn,
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1.0,
      environment: environment,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      integrations:
        integrations !== null
          ? integrations
          : [
              Sentry.browserTracingIntegration(),
              Sentry.replayIntegration({
                // Additional SDK configuration
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
                block: [],
                mask: []
              })
            ],
      ignoreErrors: SENTRY_IGNORE_ERRORS
    });
  } else if (environment === AppEnv.DEV) {
    // eslint-disable-next-line no-console
    console.log('Sentry is disabled in development mode');
  }
}

interface User {
  email?: string;
  _id?: string;
  fullName?: string;
}

export function setSentryUser(user: User | null) {
  Sentry.setUser(user ? mapUserToSentryUser(user) : null);
}

const mapUserToSentryUser = (user: User) => {
  return {
    email: user?.email ?? 'no-user-email',
    id: user?._id ?? 'no-user-id',
    username: user?.fullName ?? 'no-user-name'
  };
};
