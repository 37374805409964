import { Area } from 'react-easy-crop';

export const MIN_ZOOM = 0.4;
export const MAX_ZOOM = 3;

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

// https://codesandbox.io/s/react-easy-crop-custom-image-demo-y09komm059?from-embed=&file=/src/canvasUtils.js:714-2280
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  finalImageWidth: number,
  finalImageHeight: number
): Promise<Blob> {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const canvas2 = document.createElement('canvas');

  const maxSize = Math.max(image.width, image.height) * MAX_ZOOM;
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = finalImageWidth;
  canvas.height = finalImageHeight;

  const canvasRenderingContext2D = canvas.getContext('2d');
  if (canvasRenderingContext2D !== null) {
    canvasRenderingContext2D.fillStyle = 'white';
    canvasRenderingContext2D.fillRect(0, 0, safeArea, safeArea);

    canvas.width = safeArea;
    canvas.height = safeArea;

    // draw rotated image and store data.
    canvasRenderingContext2D.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = canvasRenderingContext2D.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    canvasRenderingContext2D.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // resizing  cropped image to final sizes by drawing on new canvas
    canvas2.width = finalImageWidth;
    canvas2.height = finalImageHeight;
    const canvasRenderingContext2D2 = canvas2.getContext('2d');
    canvasRenderingContext2D2?.drawImage(canvas, 0, 0, finalImageWidth, finalImageHeight);
  }

  const imageType = 'image/webp';

  return new Promise((resolve) => {
    canvas2.toBlob(
      (file) => {
        resolve(file as Blob);
      },
      imageType,
      0.7
    );
  });
}
